.mediaicons {
    display: flex;
    gap: 10px;

    a {
        max-width: 30px;
        text-align: center;

        i {
            font-size: 1.25rem;
            background-color: #fff;
            color: #000;
            border-radius: 50%;
            width: 30px;
            line-height: 30px;
            transition: all .5s ease;

            @media(hover:hover) {
                &:hover {
                    background-color: #1cbaa4;
                    color: #fff;
                }
            }

            @media(pointer:coarse) {
                &:active {
                    background-color: #1cbaa4;
                    color: #fff;
                }
            }
        }
    }
}