@import "./../../../responsive.scss";

.player {
    margin: 10px;
    width: 300px;
    height: 176.5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        border: 2px solid white;
        border-radius: 12px;
    }

    @include mobile {
        &:hover {
            border: unset;
            border-radius: unset;
        }
    }
}