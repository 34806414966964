// @font-face {
//     font-family: Dungeon;
//     src:
//         url(./../public/fonts/Dungeon.woff2) format('woff2'),
//         url(./../public/fonts/Dungeon.woff) format('woff'),
//         url(./../public/fonts/Dungeon.ttf) format('truetype');
// }

// @font-face {
//     font-family: Play;
//     src:
//         url(./../public/fonts/Play.woff2) format('woff2'),
//         url(./../public/fonts/Play.woff) format('woff'),
//         url(./../public/fonts/Play.ttf) format('truetype');
// }

// @font-face {
//     font-family: Humane;
//     src:
//         url(./../public/fonts/HumaneBold.woff2) format('woff2'),
//         url(./../public/fonts/HumaneBold.woff) format('woff'),
//         url(./../public/fonts/HumaneBold.ttf) format('truetype');
// }

// @font-face {
//     font-family: Helvetica;
//     src:
//         url(./../public/fonts/HelveticaNeue.woff2) format('woff2'),
//         url(./../public/fonts/HelveticaNeue.woff) format('woff'),
//         url(./../public/fonts/HelveticaNeue.ttf) format('truetype');
// }

html {
    height: 100%;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    ::-webkit-scrollbar {
        width: 0px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Play;
}

body {
    min-height: 100%;
    background-color: black;
    color: #fff;
}

a {
    text-decoration: none;
    color: inherit;
}

html.lenis,
html.lenis body {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-smooth iframe {
    pointer-events: none;
}