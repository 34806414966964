@import "./../../responsive.scss";

.spinning-button-wrapper {
    position: relative;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    overflow: hidden;

    @include mobile {
        width: 12rem;
        height: 12rem;
        font-size: 0.75rem;
    }

    .spinning-button {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0;
        animation: spin 50s linear infinite;

        span {
            position: absolute;
            font-family: Helvetica;
            text-transform: uppercase;
            top: 0;
            left: 50%;
            transform-origin: 0 7.5rem;

            @include mobile {
                transform-origin: 0 6rem
            }
        }
    }

    .imgContainer {
        width: 70%;
        height: 70%;
        display: flex;
        position: relative;
        outline: none;

        a {
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: .2rem solid #fff;
            }
        }

    }

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}