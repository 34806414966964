@import "./../../responsive.scss";

#Home {
    height: 100vh;
    width: 100%;

    .hero {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        overflow: hidden;

        .banner {
            // position: absolute;
            // width: 100%;
            // height: 100%;
            // background-image: url("./../../../public/img/cristianferreira.jpg");
            // background-size: cover;
            // background-repeat: no-repeat;

            @include mobile {
                background-position-x: 32%;
            }
        }


        .textContainer {
            text-align: center;

            .principal {
                // font-family: Dungeon;
                // font-size: 3.2rem;
                // text-shadow: rgba(0, 0, 0, 0.8) 0px 4px 5px;

                @include mobile {
                    font-size: 2.2rem;
                }
            }

            .secondary {
                // margin-top: -8px;
                // font-family: Play;
                // font-weight: normal;
                // font-size: 1.4rem;
                // text-shadow: rgba(0, 0, 0, 0.8) 0px 4px 5px;

                @include mobile {
                    font-size: 1.2rem;
                }
            }

            img {
                margin-top: 10px;
                width: 40px;

                @include mobile {
                    width: 35px;
                }
            }
        }
    }
}