@import "./../../responsive.scss";

#Contacto {
    height: 100vh;
    width: 100%;

    @include mobile {
        height: 100%;
    }

    .contacto {
        height: 100%;
        width: 100%;
        background-color: #080808;
        display: flex;
        padding: 80px 0 100px 0;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        .wrapper {
            max-width: 960px;
            width: 90%;
            display: flex;
            gap: 4%;

            @include mobile {
                flex-direction: column;
                align-items: center;
            }

            .textContainer {
                width: 48%;

                @include mobile {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .data {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 40px;
                    border: 2px solid white;
                    border-radius: 10px;
                    padding: 15px 0px;

                    @include mobile {
                        width: 100%;
                        margin: 35px 0px 15px 0px;
                        padding: 10px 0;

                    }

                    h2 {
                        font-size: 1.05rem;
                        font-weight: normal;
                        margin-bottom: 10px;

                        @include mobile {
                            font-size: 1rem;
                        }
                    }

                    .mail {
                        font-size: 1.05rem;
                        margin-bottom: 30px;

                        @include mobile {
                            font-size: 1rem;
                            margin-bottom: 20px;
                        }
                    }

                    .icon {
                        text-align: center;
                        background-color: grey;
                        color: #000;
                        width: 30px;
                        line-height: 30px;
                        font-size: 1.25rem;
                        margin-bottom: 5px;
                        border-radius: 10%;

                        @include mobile {
                            width: 25px;
                            line-height: 25px;
                            font-size: 1rem;
                        }
                    }
                }
            }

            .formContainer {
                width: 48%;

                @include mobile {
                    width: 100%;
                }

                form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 10px;

                    @include mobile {
                        gap: 6px;
                    }

                    input,
                    textarea {
                        width: 100%;
                        padding: 16px;
                        background-color: transparent;
                        border: 2px solid white;
                        color: white;
                        border-radius: 10px;
                        font-size: 1.05rem;
                        outline: none;
                        resize: none;

                        @include mobile {
                            padding: 13px;
                        }
                    }

                    button {
                        width: 100px;
                        height: 50px;                       
                        border: none;
                        outline: none;
                        font-size: 1.05rem;
                        border-radius: 30px;
                        color: #000;
                        background-color: #fff;
                        cursor: pointer;

                        @include mobile {
                            width: 90px;
                            height: 40px;
                        }

                        @media(hover:hover) {
                            &:hover {
                                background-color: #1cbaa4;
                                color: #fff;
                            }
                        }

                        @media(pointer:coarse) {
                            &:active {
                                background-color: #1cbaa4;
                                color: #fff;
                            }
                        }
                    }

                    .response {
                        width: 100%;
                        height: 35px;
                        line-height: 35px;
                        margin-top: 10px;                        
                        text-align: center;
                    }
                }
            }
        }

        .footer {
            width: 100%;
            padding: 15px 10%;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: #1c1c1c;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;

            @include mobile {
                flex-direction: column-reverse;
                justify-content: center;
                padding: 12px 0;
            }

            p {
                font-size: 1rem;

                @include mobile {
                    margin-top: 0.7rem;
                    text-align: center;
                    font-size: 0.7rem;
                }
            }

            i {
                text-align: center;
                background-color: #fff;
                color: #000;
                width: 30px;
                line-height: 30px;
                font-size: 1.25rem;
                border-radius: 50%;
                cursor: pointer;
                transition: all .5s ease;

                @media(hover:hover) {
                    &:hover {
                        background-color: #1cbaa4;
                        color: #fff;
                    }
                }

                @media(pointer:coarse) {
                    &:active {
                        background-color: #1cbaa4;
                        color: #fff;
                    }
                }
            }
        }
    }
}