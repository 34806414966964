@import "./../../responsive.scss";

#Videos {
    height: 100%;
    width: 100%;

    .videos {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        padding: 80px 0 100px 0;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        gap: 40px;

        @include mobile {
            padding: 80px 0 80px 0;
            gap: 30px;
        }

        .fuzzy {
            position: absolute;
            inset: -200%;
            background-image: url("./../../../public/img/black-noise.png");
            opacity: 20%;
            z-index: 10;
            pointer-events: none;
        }

        .wrapper {
            max-width: 960px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}