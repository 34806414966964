@import "./../../responsive.scss";

#Discos {
    height: 100%;
    width: 100%;

    .discos {
        width: 100%;
        height: 100%;
        background-color: #080808;
        display: flex;
        padding: 80px 0 100px 0;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        gap: 40px;

        @include mobile {
            padding: 80px 0 80px 0;
            gap: 5px;
        }

        .icon {
            text-align: center;
            color: white;
            width: 30px;
            line-height: 30px;
            font-size: 1.3rem;
            border-radius: 50%;
            outline: 2px solid white;
            display: none;

            @include mobile {
                display: block;
            }
        }

        .wrapper {
            max-width: 960px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}