@import "./../../responsive.scss";

.containerText {
    display: flex;
    overflow: hidden;
    z-index: 999;

    .letter {
        font-family: Humane;
        font-size: 6rem;

        @include mobile {
            font-size: 4.5rem;
        }
    }
}