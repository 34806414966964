@import "./../../responsive.scss";

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;

    .bg {
        position: fixed;
        // z-index: 999;
        top: 0;
        left: 0;
        bottom: 0;
        width: 400px;
        background-color: white;

        @include mobile {
            width: 200px;
        }

        .links {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            a {
                font-size: 25px;
                cursor: pointer;

                @include mobile {
                    font-size: 22px;
                }

                &.active {
                    color: #1cbaa4;
                }
            }
        }
    }

    button {
        z-index: 999;
        position: fixed;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: fixed;
        top: 25px;
        left: 25px;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        .burgerline {
            background: black;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5px;
            margin-bottom: 5px;
            display: block;
            height: 3px;
            width: 25px;
        }
    }

}