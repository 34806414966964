@import "./../../responsive.scss";

#Bio {
    height: 100%;
    width: 100%;

    .bio {
        height: 100%;
        width: 100%;
        display: flex;
        padding: 80px 0 100px 0;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        gap: 50px;

        @include mobile {
            padding: 80px 0 80px 0;
            gap: 35px;
        }

        .resume {
            text-align: center;
            max-width: 960px;
            padding: 0 20px;

            p {
                margin-bottom: 20px;

                span {
                    font-weight: bold;
                }
            }
        }
    }
}