@import "./../../responsive.scss";

#Galeria {
    height: 100%;
    width: 100%;

    .galeria {
        width: 100%;
        height: 100%;
        background-color: #000;
        display: flex;
        padding: 80px 0 80px 0;
        flex-direction: column;
        align-items: center;
        gap: 50px;

        @include mobile {
            gap: 35px;
        }

        .wrapper {
            max-width: 960px;
            width: 95%;
            columns: 3;
            column-gap: 0;

            @include mobile {
                columns: 2;
            }

            img {
                display: block;
                width: 100%;
                padding: 8px;
            }
        }
    }
}