.disco {
    height: 280px;
    width: 280px;
    position: relative;
    margin: 20px;
    background-color: #1c1c1c;
    cursor: pointer;

    &:hover>.art {
        top: 50%;
        right: 50%;
        width: 50%;
        height: 50%;
    }

    .textContainer {
        height: 50%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;

        h1 {
            font-family: Helvetica;
            font-size: 1.2rem;
            margin-bottom: 5px;
        }

        h2 {
            font-family: Helvetica;
            font-weight: lighter;
            font-size: 1.05rem;
            color: grey;
        }

        h3 {
            font-size: 1rem;
            font-weight: lighter;
            color: lightgrey;
        }

        h4 {
            font-size: .8rem;
            font-weight: lighter;
            color: lightgrey;
        }
    }

    .art {
        background-position: center center;
        background-size: cover;
        position: absolute;
        top: 0%;
        right: 0%;
        width: 100%;
        height: 100%;
        transition: all .4s ease-in-out;
        z-index: 10;
    }

    .listenContainer {
        height: 50%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
        position: absolute;
        bottom: 0;
        right: 0;
        gap: 10px;

        a {
            max-width: 30px;
            text-align: center;

            i {
                font-size: 1.40rem;
                background-color: #fff;
                width: 30px;
                line-height: 30px;
                color: #000;
                border-radius: 50%;
                transition: all .5s ease;

                @media (hover: hover) {
                    &:hover {
                        background-color: #1cbaa4;
                        color: #fff;
                    }
                }

                @media (pointer: coarse) {
                    &:active {
                        background-color: #1cbaa4;
                        color: #fff;
                    }
                }

            }
        }
    }
}